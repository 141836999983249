<!-- @format -->

<template>
	<div>
		<layout-wide>
			<sub-navbar page="Reminder" />
			<HeroBox
				title="Send Reminder"
				subtitle="Please Select an invoice to create a reminder for the invoice"
				color="is-danger"
			/>
			<div>
				<div v-if="loading">...Loading Please wait!!!</div>
				<div v-if="!loading">
					<div class="table-color color: white">
						<div>Unpaid Customer's Invoices Process - Send Reminder</div>
						<div>
							<b-button
								class="margin-top-20 width-250 text-bolder"
								style="background-color: rgb(52, 136, 206); color: white"
								@click="refreshData"
							>
								Refresh the data
							</b-button>
						</div>
						<div style="float: right; margin-top: -30px">
							{{ tag }}: {{ roundedCurrencyAddedNumber(totalBalance * 100) }}
						</div>
					</div>
					<div>
						<vue-good-table
							:key="keyTable"
							id="selected_dispatches"
							:columns="columns"
							:rows="rows"
							styleClass="vgt-table striped bordered"
							theme="black-rhino"
							:fixed-header="true"
							@on-row-dblclick="onRowDoubleClick"
							@on-cell-click="onCellClick"
							:search-options="{
								enabled: true,
							}"
							:pagination-options="{
								enabled: true,
								mode: 'records',
								perPage: 100,
								position: 'bottom',
								perPageDropdown: [5, 10, 15, 20, 100],
								dropdownAllowAll: false,
								setCurrentPage: 1,
								jumpFirstOrLast: true,
								firstLabel: 'First',
								lastLabel: 'Last',
								nextLabel: 'next',
								prevLabel: 'prev',
								rowsPerPageLabel: 'Rows per page',
								ofLabel: 'of',
								pageLabel: 'page', // for 'pages' mode
								allLabel: 'All',
							}"
						>
							<template slot="table-row" slot-scope="props">
								<span v-if="props.column.field === 'sendReminder'">
									<b-button class="is-small is-info" style="margin-left: 22px">
										Send
									</b-button>
								</span>
								<span v-else-if="props.column.field === 'reminderDueDate'">
									<span class="text-bold text-purple">
										{{ props.row.reminderDueDate }}
									</span>
								</span>
								<span v-else-if="props.column.field === 'invoiceBalance'">
									<p class="text-bold text-red text-align-right">
										{{ props.row.invoiceBalance }}
									</p>
								</span>
								<span v-else-if="props.column.field === 'invoiceNumber'">
									<p class="text-bold has-text-link text-align-right">
										<a href="url" style="text-decoration-line: underline">
											{{ props.row.invoiceNumber }}
										</a>
									</p>
								</span>
								<span v-else-if="props.column.field === 'reminderId'">
									<p class="text-bold has-text-link text-align-right">
										<a href="url" style="text-decoration-line: underline">
											{{ props.row.reminderId }}
										</a>
									</p>
								</span>
								<span v-else>
									{{ props.formattedRow[props.column.field] }}
								</span>
							</template>
						</vue-good-table>
						<div
							class="table-color"
							style="margin-top: -30px; min-height: 120px; margin-bottom: 50px"
						>
							<download-csv
								:data="rows"
								name="customers-to-send-reminder-list.csv"
							>
								<div style="float: right">
									<b-button
										type="is-danger"
										outlined
										class="width-250 margin-bottom-20; margin-top-30"
									>
										Download the List
									</b-button>
								</div>
							</download-csv>
						</div>
						<div v-if="!!isModalVisible">
							<ModalReminderFrame :refreshTable="initTable" :key="keyModal" />
						</div>
					</div>
				</div>
			</div>
		</layout-wide>
	</div>
</template>

<script>
import HeroBox from '@/_srcv2/pages/admin/_shared/HeroBox.vue'
import { useMutation, useQuery, useResult } from '@vue/apollo-composable'
import AddReminderMutation from '@/_srcv2/pages/reminder/graphql/AddReminderMutation.graphql'
import GetReminderNumberMutation from '@/_srcv2/pages/reminder/graphql/GetReminderNumberMutation.graphql'
import ModalReminderFrame from '@/_srcv2/pages/_reports/get-data-and-report/reminder/reminder-get-data/ModalReminderFrame.vue'
import SubNavbar from '@/_srcv2/components/_shared/sub-navbar/SubNavbar.vue'
import Store from '@/store'
import GetUnpaidInvoicesByOptionsQuery from '@/_srcv2/pages/reminder/graphql/GetUnpaidInvoicesByOptionsQuery.graphql'
import { computed, onMounted, reactive, ref } from '@vue/composition-api'

import storeAccounting from '@/_srcv2/pages/_pages-store/storeShared.js'
import useAddEditTransactionStore from '@/_srcv2/pages/transactions/add-transaction/scripts/useAddEditTransactionStore.js'

import storeReminder from '@/_srcv2/pages/reminder/_shared/useReminderState.js'
import useProcessReportStore from '@/_srcv2/pages/_reports/create-report/useProcessReportStore.js'
import moment from 'moment'

export default {
	name: 'SendReminder',
	components: {
		SubNavbar,
		HeroBox,
		ModalReminderFrame,
	},
	setup() {
		const {
			reportDocumentType,
			reportDocumentNumber,
			reportDocumentOurCompany,
			reportCustomerEmail,
			isSelectedDocumentLocked,
			isModalVisible,
			reportCustomer,
			reportDocumentDate,
			reportDocumentStatus,
			isSelectedDocumentReported,
		} = useProcessReportStore()

		const { reportOurCompany, reportReminderNumber } = storeReminder() // *********************
		// 3days
		const getDueDate = (term) => {
			let date = new Date()
			let daysToSubtract
			switch (moment(date).day()) {
				// Sunday = term + 1 days
				case 0:
					daysToSubtract = term + 1
					break
				// Monday and Tuesday = term + 2 days
				case 1:
				case 2:
					daysToSubtract = term + 2
					break
				// Subtract term days otherwise.
				default:
					daysToSubtract = term
					break
			}
			return moment(date).subtract(daysToSubtract, 'days').format('YYYY-MM-DD')
		}
		// ***************************************************************************************
		// 3days
		const setDueDate = (date, term) => {
			const documentDueDate = moment(date, 'YYYY-MM-DD').add(term, 'days')
			console.log('documentDueDate', documentDueDate)
			let daysToAdd
			switch (moment(date).day()) {
				// Sunday = 1 day
				case 0:
					daysToAdd = 1
					break
				// Monday, Tuesday, Wednesday and Thursday = 0 day
				case 1:
				case 2:
				case 3:
				case 4:
				case 5:
					daysToAdd = 0
					break
				case 6:
					daysToAdd = 2
					break
				// Add term days otherwise.
				default:
					daysToAdd = term
					break
			}
			return moment(documentDueDate).add(daysToAdd, 'days').format('YYYY-MM-DD')
		}
		console.log(' ***** set due date', setDueDate('2022-03-25', 7))
		// todo ------------------------------------------------------------------------------
		const keyTable = ref(1)
		onMounted(() => {
			initTable()
		})

		const initTable = () => {
			console.log('init is fired')
			refreshData()
			setVariablesForTable(false)
			showWhich.value = 'notSent'
			keyTable.value += 1
		}

		// ? Get Data from database
		const options = reactive({
			fetchPolicy: 'no-cache',
		})
		const variablesForTable = reactive({
			is_invoice_paid: 'false',
			our_company: Store.getters.getUserCurrentCompany,
			is_invoice_reminded: false,
			is_sent_in_the_bailiffs: false,
			invoice_due_date: getDueDate(0),
			is_doubtful_receivable: false,
		})
		const setVariablesForTable = (isReminded) => {
			return new Promise((resolve, reject) => {
				if (variablesForTable.our_company !== null || undefined) {
					variablesForTable.is_invoice_paid = 'false'
					variablesForTable.is_invoice_reminded = isReminded
					variablesForTable.is_sent_in_the_bailiffs = false
					variablesForTable.invoice_due_date = getDueDate(0)
					variablesForTable.is_doubtful_receivable = false
					resolve(result)
				} else {
					const reason = new Error('Data could not be fetched from database')
					reject(reason)
				}
			})
		}
		const { result, loading, onResult, refetch } = useQuery(
			GetUnpaidInvoicesByOptionsQuery,
			() => variablesForTable,
			options,
		)
		const searchedInvoices = useResult(
			result,
			null,
			(data) => data.document_transactions,
		)
		console.log('**************** searchedInvoices', searchedInvoices)
		// todo -----------------------------------------------------------------------------
		const getUnpaidInvoicesToBeReminded = () => {
			setVariablesForTable(false).then(() => refetch())
			keyTable.value = keyTable.value + 1
			tag.value = `Total Balance of unpaid invoices: `
		}
		// ? Create the table columns
		const columns = reactive([
			{
				label: '*',
				field: 'isItOverdue',
				width: '20px',
			},
			{
				label: 'Invoice Number',
				field: 'invoiceNumber',
				width: '115px',
			},
			{
				label: 'Customer',
				field: 'customer',
			},
			{
				label: 'Invoice date',
				field: 'invoiceDate',
				width: '121px',
			},
			{
				label: 'Invoice due date',
				field: 'invoiceDueDate',
				width: '121px',
			},
			{
				label: 'Invoice Balance',
				field: 'invoiceBalance',
				width: '135px',
				html: true,
			},
			{
				label: 'Send Reminder',
				field: 'sendReminder',
				width: '120px',
				sortable: false,
			},
		])

		// ? Create the table rows
		const getStarForOverdueInvoices = (invoiceDueDate) => {
			console.log('dueDate', getDueDate(3))
			if (invoiceDueDate <= getDueDate(3)) {
				return '*'
			} else return ''
		}
		// ***************************************************************************************
		const { roundedCurrencyAddedNumber } = storeAccounting()

		const { invoiceToPay, invoicePayment, invoiceBalance } =
			useAddEditTransactionStore()
		// ***************************************************************************************
		const rows = computed(() =>
			searchedInvoices.value
				.map((row) => {
					return {
						customer:
							row.customer.customer_id +
							' - ' +
							row.customer.customer_title +
							' - ' +
							row.customer.customer_nickname,
						customerId: row.customer.customer_id,
						customerTitle: row.customer.customer_title,
						customerNickname: row.customer.customer_nickname,
						customerEmail: row.customer.customer_email,
						invoiceNumber: row.invoice_number,
						invoiceDate: row.invoice_date,
						invoiceDueDate: row.invoice_due_date,
						isInvoicePaid: row.is_invoice_paid,
						isInvoiceReminded: row.is_invoice_reminded,
						isSentInTheBailiffs: row.is_sent_in_the_bailiffs,
						reminderId: row?.reminder_lines?.reminder_id || '',
						reminderDate:
							row?.reminder_lines?.reminder_header?.reminder_date || '',
						reminderDueDate:
							row?.reminder_lines?.reminder_header?.reminder_due_date || '',
						addressId: row.invoice_address_id,
						invoiceToPay: invoiceToPay(row).invoiceTotalDb,
						invoicePayment: invoicePayment(row).paymentDb,
						invoiceBalance: invoiceBalance(row).correctedBalance,
						saleInvoiceBalanceToDb: parseFloat(invoiceBalance(row).balanceDb),
						isItOverdue: getStarForOverdueInvoices(row.invoice_due_date),
						ourCompany: row.our_company,
					}
				})
				.sort(function (a, b) {
					const invoiceDueDateA = a.invoiceDueDate
					const invoiceDueDateB = b.invoiceDueDate
					if (invoiceDueDateA < invoiceDueDateB) {
						return -1
					}
					if (invoiceDueDateA > invoiceDueDateB) {
						return 1
					}
					// dates must be equal
					return 0
				}),
		)
		// ? Create the table functions
		const onCellClick = (params) => {
			console.log('*** params.row', params.row)
			console.log('*** params.row.invoiceNumber', params.row.invoiceNumber)
			companyNickname.value = params.row.ourCompany
			invoiceNumber.value = params.row.invoiceNumber
			reportOurCompany.value = params.row.ourCompany
			console.log('*** reportOurCompany.value', reportOurCompany.value)
			if (params.column.field === 'sendReminder') {
				if (confirm('Are you sure to send reminder to customer?!'))
					addReminder(params.row)
			}
		}
		const onRowDoubleClick = (params) => {
			return params.row
		}

		// ? Get total balance
		const tag = ref(`Total Balance of unpaid invoices: `)
		const totalBalance = ref(0)
		onResult(() => {
			console.log(' new result is fetched', result.value)
			console.log('rows', rows.value)
			const arr = rows.value.map((item) => item.saleInvoiceBalanceToDb)
			console.log('arr', arr)
			totalBalance.value = arr.reduce((a, b) => a + b, 0)
			console.log('totalBalance', totalBalance.value)
		})
		const refreshData = () => {
			refetch().then(() => window.alert(' The data is refreshed'))
		}

		// todo -------------------------------------------------------------------
		const companyNickname = ref('')
		const reminderId = ref('')
		const invoiceNumber = ref('')
		const keyModal = ref(1)
		const addReminder = (row) => {
			console.log('*** addReminder row', row)
			reportCustomerEmail.value = row.customerEmail
			getNewReminderNumber()
				.then((result) => {
					const letterCode =
						result.data.update_companies.returning[0].letter_code
					const reminderNumber =
						result.data.update_companies.returning[0].reminder_number
					reminderId.value = `${letterCode} ${reminderNumber}`
					reportReminderNumber.value = `${letterCode} ${reminderNumber}`
					console.log(
						'*** reportReminderNumber.value',
						reportReminderNumber.value,
					)
					console.log('mutationVariables', mutationVariables)
					setMutationVariables(row)
					console.log('mutationVariables after', mutationVariables)
				})
				.then(addNewReminder)
				.then((result) => {
					console.log('mutation AddReminder result', result)
					const row = result.data.insert_reminder_headers.returning[0]
					console.log('mutation AddReminder row', row)
					reportDocumentType.value = 'reminder'
					reportDocumentNumber.value = row.reminder_id
					reportDocumentOurCompany.value = row.company_nickname
					isSelectedDocumentLocked.value = true
					isSelectedDocumentReported.value = true
					reportCustomer.value = `${row._customer.customer_id} - ${row._customer.customer_title} - ${row._customer.customer_nickname}`
					// reportCustomer.value = row._customer
					reportDocumentDate.value = row.reminder_date
					reportDocumentStatus.value = 'reminded'
					keyModal.value += 1
				})
				.then(() => (isModalVisible.value = true))
			// .then(() => alert('push to report data'))
		}
		// todo -------------------------------------------------------------------
		const { mutate: getNewReminderNumber } = useMutation(
			GetReminderNumberMutation,
			() => ({
				variables: {
					company_nickname: companyNickname.value,
				},
			}),
		)
		// ------------------------------------------------------------------
		const reminderLines = []
		const mutationVariables = {
			reminder_id: '',
			company_nickname: '',
			customer_id: '',
			is_reminder_fee_paid: false,
			is_reminder_sent: true,
			is_sent_in_the_bailiffs: false,
			reminder_address_id: '',
			reminder_date: '', //* today
			reminder_due_date: '', //* today + 7 work days
			reminder_fee: 6000,
			// ************************************************************************
			reminder_headers_reminder_lines_rel: {
				data: reminderLines,
			},
		}
		// todo ------------------------------------------------------------------------
		const setMutationVariables = (row) => {
			console.log('*** setMutationVariables row', row)
			// ! get today
			let dateReminder = new Date()
			const today = moment(dateReminder).format('YYYY-MM-DD')
			// todo ------------------------------------------------------------------
			reminderLines.length = 0
			reminderLines.push({
				customer_id: row.customerId,
				invoice_balance: parseFloat(row.saleInvoiceBalanceToDb) * 100,
				invoice_date: row.invoiceDate,
				invoice_due_date: row.invoiceDueDate,
				invoice_number: row.invoiceNumber,
				invoice_payment: parseFloat(row.invoicePayment) * 100,
				invoice_to_pay: parseFloat(row.invoiceToPay) * 100,
				our_company: row.ourCompany,
			})
			//************************************************************
			mutationVariables.reminder_id = reminderId.value
			mutationVariables.company_nickname = row.ourCompany
			mutationVariables.customer_id = row.customerId
			mutationVariables.is_reminder_fee_paid = false
			mutationVariables.is_sent_in_the_bailiffs = false
			mutationVariables.is_reminder_sent = true
			mutationVariables.reminder_address_id = row.addressId
			mutationVariables.reminder_date = today
			mutationVariables.reminder_due_date = setDueDate(today, 7)
			mutationVariables.reminder_fee = 6000
		}
		// todo -------------------------------------------------------
		const { mutate: addNewReminder } = useMutation(AddReminderMutation, () => ({
			variables: {
				input: computed(() => mutationVariables).value,
				invoice_number: computed(() => invoiceNumber.value).value, // invoiceNumber.value,
				our_company: computed(() => companyNickname.value).value, // companyNickname.value,
			},
		}))
		// --------------------------------------------------------
		const showWhich = ref('notSent')
		const logRows = () => console.log('rows', rows)
		return {
			logRows,
			loading,
			tag,
			roundedCurrencyAddedNumber,
			totalBalance,
			searchedInvoices,
			refreshData,
			columns,
			rows,
			onCellClick,
			onRowDoubleClick,
			getUnpaidInvoicesToBeReminded,
			showWhich,
			keyTable,
			//****************
			mutationVariables,
			setMutationVariables,
			isModalVisible,
			keyModal,
			initTable,
			// downloadList,
		}
	},
}
</script>

<style scoped></style>
